import React, { Component } from 'react';
import { LANGUAGES } from './../infrastructure/constants';

export default class AddChannel extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: ''
		}
	}

	onChangeName(e) {
		this.setState({
			name: e.target.value
		})
	}

	onSubmit() {
		this.props.onSubmit(this.state);
	}

	render() {
		const isFormValid = !!this.state.name.length;

		const { error } = this.props;

		return (
			<div class="chat-main__main-content">
				<div class="settings-page">
					<div class="settings-page__main single-channel-add">
						<div class="settings-header">
							<div class="channel-settings-row">
								<div class="channel-settings-row__title channel-settings-row__title--bold">Добавить отдел</div>
								<div class="channel-settings-row__items">
									<div class="options-list">
										<p class="form_error">{error}</p>
										<div class="option-item">
											<div class="option-item__title">Название</div>
											<div class="option-item__element">
												<input
													type="text"
													name="channel_site"
													id="channel-site"
													onChange={this.onChangeName.bind(this)}
													value={this.state.name}
													placeholder="Имя отдела" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="settings-main">
							{/* <div class="channel-row"> */}
							{/* <div class="channel-row__heading">
									<div class="channel-row__heading-buttons">
										<button class="btn btn--green btn--green-w">+ Добавить оператора</button>
									</div>
								</div>
								<div class="channel-row__users">
									<div class="user-unit">
										<img src="assets/img/user-photo-placeholder.jpg" alt="User" class="user-unit__img" />
										<div class="user-unit__name">Артур</div>
									</div>
									<div class="user-unit">
										<img src="assets/img/user-photo-placeholder.jpg" alt="User" class="user-unit__img" />
										<div class="user-unit__name">Николай</div>
									</div>
									<div class="user-unit">
										<img src="assets/img/user-photo-placeholder.jpg" alt="User" class="user-unit__img" />
										<div class="user-unit__name">Сергей</div>
									</div>
								</div> */}

							{/* </div> */}
							<div class="action-row">
								<button
									onClick={this.onSubmit.bind(this)}
									disabled={!isFormValid}
									class="btn btn--green action-button">+ Добавить отдел</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}