
const store = localStorage;

export function setData(key, data) {
    store.setItem(key, data);
}

export function getData(key) {
    const data = store.getItem(key);
    return data ? data : {};
}

export function deleteKey(key) {
    store.removeItem(key);
}

export function clear() {
    store.clear();
}
