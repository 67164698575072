
function createUID(message: any, date: Date) {
	const res = message + date;
	return res.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0)
}

function employee(text, token) {
	const createdAt = new Date();
	return {
		uid: createUID(text, createdAt),
		value: text,
		createdAt,
		employee: {},
		token,
		type: 1,
		author: 2
	}
}

function close({ createdAt }) {
	return {
		type: 2,
		event: 1,
		author: 1,
		createdAt
	};
}

export default {
	employee,
	close
}