import { DIALOG_STATUSES } from "../infrastructure/constants";

export default class Dialog {
	token = null;
	channelId = null;
	departmentId = null;
	client = null;
	employee = null;
	unread = 0;
	#last = null;
	#status = null;
	#page = null;
	constructor({ channelId, page, token, departmentId, status, client, employee }) {
		this.token = token;
		this.channelId = channelId;
		this.departmentId = departmentId;
		this.status = status;
		this.client = client;
		this.employee = employee;
		this.page = page;

		this.formatClientName();
	}

	update({ departmentId, status, page, employee }) {
		this.departmentId = departmentId;
		this.status = status;
		this.employee = employee;
		this.page = page;
	}

	up() {
		this.status = DIALOG_STATUSES.ACTIVE;
	}

	accept() {
		this.status = DIALOG_STATUSES.ACCEPTED;
	}

	close() {
		this.status = DIALOG_STATUSES.CLOSED;
	}

	active() {
		this.status = DIALOG_STATUSES.ACTIVE;
		this.employee = null;
	}

	closeByClient() {
		this.status = 5;
	}

	addUnread() {
		this.unread += 1;
	}

	resetUnreadMessages() {
		this.unread = 0;
	}

	setMessageLast(message) {
		this.last = message;
	}
	setDepartment(departmentId) {
		this.departmentId = departmentId;
	}

	getPage = () => this.page;
	getLastMessage = () => this.last;

	isAccepted = () => this.status == DIALOG_STATUSES.ACCEPTED;
	isEmployeeOwner = (employeeId) => this.employee?.id == employeeId;
	isClosedByClient = () => this.status == DIALOG_STATUSES.CLOSED_BY_CLIENT;

	formatClientName() {
		if (!this.client.name) {
			this.client.name = `Клиент ${this.client.id}`;
		}
	}
}