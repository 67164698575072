import EmployeeApi from './../api/employees';

const ENDPOINT = 'employees/';

export const SET_LIST = 'SET_EMPLOYEE_LIST';

export function getByChannel(id) {
    return async (dispatch) => {
        const { result, data } = await EmployeeApi.getListByChannel(id);
        if (result) {
            return dispatch({
                type: SET_LIST,
                payload: data
            })
        }
    }
}

