import Api from '.';

class Message extends Api {
	ENDPOINT = 'messages/'

	constructor() {
		super();
	}

	getByToken(token) {
		return this.get(`${this.ENDPOINT}${token}`);
	}

	getList({ channelId, clientId, limit}){
		return this.get(`messages?clientId=${clientId}&&channelId=${channelId}&&limit=${limit}`);
	}
}

const message = new Message();

export default message;