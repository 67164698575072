import { SETTINGS_ITEMS } from './infrastructure/constants';
import SettingsApi from './api/settings';

class SettingsStore {
	#api = null;
	#store = {};
	constructor(api) {
		this.api = api;
		this.store = {};
	}

	async get(channelId) {
		if (!this.store[channelId]) {
			this.store[channelId] = await this.upload(channelId);
		}
		return this.store[channelId];
	}

	async upload(channelId) {
		const { result, data } = await this.api.getByChannel(channelId);
		if (!result) {
			return {};
		}
		return data;
	}

	async set(channelId, data) {
		data = this.filterData(data);
		await this.api.setByChannel(channelId, data);
		this.store[channelId] = Object.assign(this.store[channelId], data);
	}

	filterData(data) {
		const result = {};
		for (const item in data) {
			if (SETTINGS_ITEMS.includes(item)) {
				result[item] = data[item];
			}
		}
		return result;
	}
}

const settingsStore = new SettingsStore(SettingsApi);

export default settingsStore;