// @flow
import React from 'react';
import { Provider } from 'react-redux';
import App from './app';
import { hot } from 'react-hot-loader/root';
import store from './store';



const Root = () => (
	<Provider store={store}>
		<App />
	</Provider>
);

export default hot(Root);
