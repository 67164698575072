import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Routes from './../../infrastructure/routes.json';

export default class SettingsChannels extends Component {
	renderChannel(channel) {
		console.log(`${Routes.SETTINGS_MAIN}/${channel.id}`);
		return (
			<li><Link to={`${Routes.SETTINGS_MAIN}${channel.id}`}>{channel.name}</Link></li>
		)
	}
	render() {
		const { channels } = this.props;

		return (
			<div class="chat-main__main-content">
				<div class="settings-page">
					<div class="settings-page__side">
						<div class="settings-channels">
							<div class="settings-channels__top">
								<div class="title-box">
									<div class="title-box__pre-title">Каналы</div>
								</div>
								<div class="menu-block">
									<ul class="menu-block__list">
										{channels.map(this.renderChannel.bind(this))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
