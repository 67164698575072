import MessageApi from '../api/messages';

const ENDPOINT = 'message/';

export const SET_LIST = 'SET_MESSAGE_LIST';

export function getByClient(token) {
	return async (dispatch) => {
		const { result, data } = await MessageApi.getByToken(token);
		if (result) {
			return dispatch({
				type: SET_LIST,
				list: data
			});
		}
	};
}
