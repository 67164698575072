import React, { Fragment } from 'react';
import MessageList from '../MessageList';
import Container from './container';
import Input from './input';
import Manage from './manage';
import Actions from './actions';
import { DIALOG_STATUSES } from "../../infrastructure/constants";

export default ({ client, status, page, department, employee, onUpdate, onStatusChange, renderEmployeeName, messages, onSend, onFileUpload, isAccepted, isEmployeeOwner }) => {
	return (
		<Fragment>
			<Container>
				<MessageList
					renderEmployeeName={renderEmployeeName}
					client={client}
					source={page}
					messages={messages}
				/>
				{(status == DIALOG_STATUSES.CLOSED_BY_CLIENT || isAccepted) && isEmployeeOwner ?
					<Input
						onFileUpload={onFileUpload}
						onSend={onSend} /> :
					<Manage
						status={status}
						onStatusChange={onStatusChange}
						isAccepted={isAccepted}
						isCurrentEmployeeOwner={isEmployeeOwner}
						onUpdate={onUpdate}
					/>}
			</Container>
			<Actions
				status={status}
				onStatusChange={onStatusChange}
				statusChangeEnabled={!isAccepted || isEmployeeOwner}
				employee={renderEmployeeName(employee)}
				client={client} />
		</Fragment>
	)
}