import io from 'socket.io-client';

// const SOCKET_URL = 'http://localhost:3002?type=employee';
const SOCKET_URL = 'https://support-message-core.app.avtodel.com?type=employee';

const CONNECT_EMPLOYEE = 'employee-handshake';
const ACCEPT_DIALOG = 'on-employee-accepted-dialog';
const SET_TO_ACTIVE_DIALOG = 'on-set-to-active-dialog';
const CLOSE_DIALOG = 'on-employee-close-dialog';
const SEND_MESSAGE = 'on-employee-message';
const ON_DIALOG_UPDATE = 'on-dialog-update';
const ON_ONLINE_DIALOGS = 'on-online-dialogs';
const ON_CLIENT_MESSAGE = 'on-client-message';
const ON_CLIENT_CLOSE = 'on-client-close';
const UPDATE_SETTINGS = 'on-employee-set-settings';
const UPDATE_DEPARTMENTS = 'on-employee-set-channel-departments';

export class ChatAPI {
	#token = null;

	constructor(token, employeeId) {
		this.employeeId = employeeId;
		this.token = token;
	}

	connect() {
		this.socket = io(SOCKET_URL, {
			transports: ["websocket"]
		});

		console.log('EMIIITT: ', this.token)
		console.log({ token: this.token })
		console.log(this.socket.connected)
		this.socket.emit(CONNECT_EMPLOYEE, { token: this.token, k: 23232});

		this.on('reconnect', () => {
			this.socket.emit(CONNECT_EMPLOYEE, { token: this.token });
		});
	}

	onDialogUpdate(handler) {
		this.on(ON_DIALOG_UPDATE, handler);
	}

	onOnlineDialogs(handler) {
		this.on(ON_ONLINE_DIALOGS, handler);
	}

	onClientMessage(handler) {
		this.on(ON_CLIENT_MESSAGE, handler);
	}

	onClientClose(handler) {
		this.on(ON_CLIENT_CLOSE, handler);
	}

	acceptDialog(data) {
		this.emit(ACCEPT_DIALOG, data);
	}

	closeDialog(data) {
		this.emit(CLOSE_DIALOG, data);
	}

	setToActiveDialog(data) {
		this.emit(SET_TO_ACTIVE_DIALOG, data);
	}

	updateSettings(channelId){
		this.emit(UPDATE_SETTINGS, channelId);
	}

	updateDepartments(channelId){
		this.emit(UPDATE_DEPARTMENTS, channelId);
	}

	sendMessage(message) {
		this.emit(SEND_MESSAGE, message);
	}

	on(event, func) {
		this.socket.on(event, func);
	}

	emit(event, data) {
		this.socket.emit(event, data);
	}
}
