import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class SettingsEmployees extends Component {

	renderEmployee(employee) {
		if (this.props.isLoggedEmployee(employee.id)) {
			return null;
		}
		return (
			<div class="channel-row">
				<div class="channel-row__heading">
					<div class="channel-row__heading-title">{employee.name}</div>
					<div class="channel-row__heading-buttons">
						<button onClick={() => this.props.edit(employee.id)} class="btn btn--green btn--green-w">Редактировать</button>
						<button onClick={() => this.props.remove(employee.id)} class="btn btn--red btn--red-w">Удалить</button>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { list, onAddSubmit } = this.props;
		return (
			<div class="settings-page__main single-channel-departments">
				<div class="settings-header">
					<div class="settings-header__main-title">
						<div class="main-title">Операторы</div>
						<Link onClick={onAddSubmit}>
							<button class="text-button text-button--green">+ Добавить</button>
						</Link>
					</div>
				</div>
				<div class="settings-main">
					<div class="channels-list">
						{list.map(this.renderEmployee.bind(this))}
					</div>
				</div>
			</div>
		)
	}
}

