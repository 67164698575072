import React, { Component } from 'react';
import { ROLES_LIST } from '../infrastructure/constants';

export default class EmployeeItem extends Component {
	constructor(props) {
		super(props);
		const employee = props.employee;
		this.state = {
			name: employee?.name ? employee.name : '',
			email: employee?.email ? employee.email : '',
			password: '',
			role: employee?.role ? employee.role : ROLES_LIST[0].id
		}
	}

	onChangeName(e) {
		this.setState({
			name: e.target.value
		})
	}

	onChangePassword(e) {
		this.setState({
			password: e.target.value
		})
	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value
		})
	}

	onChangeRole(e) {
		this.setState({
			role: e.target.value
		})
	}

	onSubmit() {
		this.props.onSubmit(this.state);
	}

	render() {
		const { error, isEdit } = this.props;

		const isFormValid = !!this.state.name.length && !!this.state.email.length && (isEdit || !!this.state.password.length);

		return (
			<div class="chat-main__main-content">
				<div class="settings-page">
					<div class="settings-page__main single-channel-add">
						<div class="settings-main">
							<div class="add-operator-form">
								<div class="channel-settings-row">
									<div class="channel-settings-row__title channel-settings-row__title--bold">Добавить оператора</div>
									<div class="form-unit">
										<p class="form_error">{error}</p>
									</div>
									<div class="channel-settings-row__items">
										<div class="options-list">
											<div class="option-item">
												<div class="option-item__title">Имя:</div>
												<div class="option-item__element">
													<input
														value={this.state.name}
														onChange={this.onChangeName.bind(this)}
														type="text" name="operator_name"
														id="operator-name" placeholder="Имя" />
												</div>
											</div>
											<div class="option-item">
												<div class="option-item__title">Должность:</div>
												<div class="option-item__element">
													<select onChange={this.onChangeRole.bind(this)} type="text" name="operator_job" id="operator-job" placeholder="-" >
														{ROLES_LIST.map((role) => <option selected={role.id == this.state.role} value={role.id}>{role.name}</option>)}
													</select>
												</div>
											</div>
											<div class="option-item">
												<div class="option-item__title">E-mail:</div>
												<div class="option-item__element">
													<input
														value={this.state.email}
														onChange={this.onChangeEmail.bind(this)}
														type="email"
														name="operator_email"
														id="operator-email" placeholder="-" />
												</div>
											</div>
											{!isEdit ?
												<div class="option-item">
													<div class="option-item__title">Пароль:</div>
													<div class="option-item__element">
														<input
															value={this.state.password}
															onChange={this.onChangePassword.bind(this)}
															type="password"
															name="operator_pass"
															id="operator-pass"
															placeholder="-" />
													</div>
												</div>
												: null}
										</div>
									</div>
								</div>
								<div class="action-row">
									<button disabled={!isFormValid} onClick={this.onSubmit.bind(this)} class="btn btn--green action-button">Сохранить</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}