import Api from '.';

class Settings extends Api {
	ENDPOINT = 'settings/'

	constructor() {
		super();
	}

	getByChannel(channelId) {
		return this.get(`${this.ENDPOINT}${channelId}`);
	}

	setByChannel(channelId, data) {
		return this.patch(`${this.ENDPOINT}${channelId}`, data);
	}
}

const settings = new Settings();

export default settings;