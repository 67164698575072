import Api from '.';

class Channel extends Api {
	ENDPOINT = 'channels/'

	constructor() {
		super();
	}

	add(data) {
		return this.post(this.ENDPOINT, data);
	}

	remove(id) {
		return this.delete(`${this.ENDPOINT}${id}`);
	}

	getListByEmployee(employeeId) {
		console.log('tut', employeeId)
		return this.get(`channels`, { employeeId });
	}
}

const channel = new Channel();

export default channel;