import Api from '.';

class Channel extends Api {
	ENDPOINT = 'departments/'

	constructor() {
		super();
	}

	add(data) {
		return this.post(this.ENDPOINT, data);
	}

	remove(id) {
		return this.delete(`${this.ENDPOINT}${id}`);
	}

	getListByEmployee(employeeId) {
		return this.get(`${this.ENDPOINT}?employeeId=${employeeId}`);
	}

	getListByChannel(channelId) {
		console.log(channelId);
		return this.get(`${this.ENDPOINT}?channelId=${channelId}`);
	}

	linkEmployees(id, data) {
		return this.patch(`${this.ENDPOINT}${id}/employees`, data);
	}
}

const channel = new Channel();

export default channel;