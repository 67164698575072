// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmployeeApi from './../api/employees';
import { getByChannel as getEmployees } from '../actions/employee';
import Routes from '../infrastructure/routes.json';
import SettingsContainer from '../components/Settings/Container';
import Employees from '../components/Settings/Employees';
import Loading from '../components/Loading';
import { ROLES } from '../infrastructure/constants';


class SettingsEmployeesPage extends Component {

	state = {
		isLoading: true
	}

	async componentDidMount() {
		this.channel = this.props.channels.find((c) => c.id == this.props.match.params.id);
		await this.getEmployees();
		this.setState({
			isLoading: false
		});
	}

	async getEmployees() {
		await this.props.getEmployees(this.channel.id);
	}

	async remove(employeId) {
		this.setState({
			isLoading: true
		});
		await EmployeeApi.remove(employeId);
		await this.getEmployees();
		this.setState({
			isLoading: false
		});
	}

	onEdit(employeId) {
		this.props.history.push(`${Routes.EDIT_EMPLOYEE}${this.channel.id}/${employeId}`);
	}

	isLoggedEmployee(id) {
		return id == this.props.accountId;
	}

	onAddEmployeeSubmit() {
		this.props.history.push(`${Routes.ADD_EMPLOYEE}${this.channel.id}`)
	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}
		return (
			<SettingsContainer
				isManager={ROLES.MANAGER == this.props.accountRole}
				channel={this.channel}>
				<Employees
					isLoggedEmployee={this.isLoggedEmployee.bind(this)}
					onAddSubmit={this.onAddEmployeeSubmit.bind(this)}
					edit={this.onEdit.bind(this)}
					remove={this.remove.bind(this)}
					list={this.props.employees} />
			</SettingsContainer>
		);
	}
}

function mapStateToProps(state) {
	return {
		departments: state.department.list,
		channels: state.channel.list,
		employees: state.employee.list,
		accountId: state.account.id,
		accountRole: state.account.role
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getEmployees: (channelId) => dispatch(getEmployees(channelId))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsEmployeesPage);
