import { API_URL } from './../config';
import { getToken as getLocalToken, removeToken as removeLocalToken, saveToken as saveLocalToken } from './../localStorage';

class Api {
	static token = null;

	constructor() {
		this.token = getLocalToken();
	}

	login({ email, password }) {
		this.logout();
		return this.get(`auth/login`, {}, {
			Authorization: `Basic ${this.encodeAuthorization(`${email}:${password}`)}`
		});
	}

	encodeAuthorization(data) {
		return new Buffer(data).toString('base64');
	}

	post(path, body, headers = {}) {
		let request = {};
		request.headers = headers;
		request.method = 'POST';
		request.body = JSON.stringify(body);
		return this.fetch({ path, request, headers })
	}

	get(path, params, headers = {}) {
		let request = {};
		request.headers = headers;
		request.method = 'GET';
		path = path + this.formatGetParams(params);
		console.log(path);
		return this.fetch({ path, request })
	}

	put(path, body, headers = {}) {
		let request = {};
		request.headers = headers;
		request.method = 'PUT';
		request.body = JSON.stringify(body);
		return this.fetch({ path, request })
	}

	patch(path, body, headers = {}) {
		let request = {};
		request.headers = headers;
		request.method = 'PATCH';
		request.body = JSON.stringify(body);
		return this.fetch({ path, request })
	}

	delete(path, headers = {}) {
		let request = {};
		request.headers = headers;
		request.method = 'DELETE';
		return this.fetch({ path, request })
	}

	formatGetParams(params = {}) {
		if (Object.keys(params).length == 0) {
			return '';
		}
		let result = '?';
		for (const param in params) {
			result += `${param}=${params[param]}`;
		}
		return result;
	}

	isLogged = () => !!this.token;

	logout = () => this.removeToken();

	fetch({ path, request }) {
		request.headers['Content-type'] = 'application/json';
		if (this.token) request.headers.Authorization = `Bearer ${this.token}`;
		return fetch(API_URL + path, request)
			.then(async response => {
				const result = await response.json();
				if (this.isSuccessStatus(response.status)) {
					return {
						result: true,
						data: result
					}
				}
				else {
					return {
						result: false,
						error: result.error
					}
				}
			})
			.catch(({ message }) => {
				throw new Error('SERVER_UNVAILABLE');
			});
	}

	isSuccessStatus(status) {
		return status == 200 || status == 201;
	}

	getToken() {
		return this.token;
	}

	removeToken() {
		this.token = null;
		removeLocalToken();
	}

	saveToken(token) {
		this.token = token;
		saveLocalToken(token);
	}
}


export default Api;