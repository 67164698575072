// @flow
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import OnlineList from '../components/OnlineList';
import Chat from '../components/Chat';
import Loading from '../components/Loading';
import { TAB_LIST, DIALOG_STATUSES } from '../infrastructure/constants';

class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTab: TAB_LIST.INBOX,
			isLoading: true
		}
		this.dialogs = null;
		this.messages = [];
		this.currentDialog = null;
	}

	componentDidMount() {
		this.dialogs = this.props.chatGateway.getDialogs();
		this.props.chatGateway.setDialogsListener((dialogs) => this.onDialogsUpdate(dialogs))
		this.props.chatGateway.setMessagesListener((message) => this.onMessage(message))
		this.setState({
			isLoading: false
		})
	}

	onMessage(message) {
		this.messages.push(message);
		this.forceUpdate();
	}

	onDialogsUpdate(dialogs) {
		if (this.currentDialog) {
			this.currentDialog = this.dialogs.getByToken(this.currentDialog.token);
		}
		this.dialogs = dialogs;
		this.forceUpdate();
	}


	renderEmployeeName(employee) {
		if (!employee) {
			return 'Нет';
		}
		if (employee.id == this.props.employeeId) {
			return 'Вы';
		}
		return employee.name;
	}

	async onDialogPress(dialog) {
		this.setState({
			isLoading: true
		});
		this.currentDialog = dialog;
		this.props.chatGateway.setCurrentDialogToken(dialog.token);
		this.messages = await this.props.chatGateway.getMessages(dialog);
		console.log(this.messages);
		this.setState({
			isLoading: false
		});
	}

	onSend(text) {
		const message = this.props.chatGateway.sendTextMessage(this.currentDialog, text);
		this.messages.push(message);
		this.forceUpdate();
	}

	onSetTab(tab) {
		this.setState({
			currentTab: tab
		})
	}

	async onUpdate() {
		this.setState({
			isLoading: true
		});
		this.messages = await this.props.chatGateway.getMessages(this.currentDialog.client.id, {
			forceUpdate: true
		});
		this.setState({
			isLoading: false
		});
	}

	onStatusChange(status) {
		if (DIALOG_STATUSES.CLOSED == status) {
			this.props.chatGateway.closeDialog(this.currentDialog);
		}
		else if (DIALOG_STATUSES.ACTIVE == status) {
			this.props.chatGateway.setToActiveDialog(this.currentDialog);
		}
		else if (DIALOG_STATUSES.ACCEPTED == status) {
			this.props.chatGateway.acceptDialog(this.currentDialog);
		}
	}

	filterInbox = (d) => {
		return d.status == DIALOG_STATUSES.ACTIVE ||
			d.status == DIALOG_STATUSES.CLOSED_BY_CLIENT ||
			d.status == DIALOG_STATUSES.ACCEPTED && d.isEmployeeOwner(this.props.employeeId);
	}

	filterAll = (d) => {
		return d.status == DIALOG_STATUSES.CLOSED
			&& d.isEmployeeOwner(this.props.employeeId);
	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}

		let dialogs = [];

		if (this.state.currentTab == TAB_LIST.ALL) {
			dialogs = this.dialogs.list.filter((d) => this.filterAll(d));
		}
		else {
			dialogs = this.dialogs.list.filter((d) => this.filterInbox(d));
		}

		return (
			<Fragment>
				<OnlineList
					onSetTab={this.onSetTab.bind(this)}
					dialogs={dialogs}
					currentTab={this.state.currentTab}
					currentToken={this.currentDialog?.token}
					onDialogPress={this.onDialogPress.bind(this)}
					renderEmployeeName={this.renderEmployeeName.bind(this)}
					departments={this.props.departments}
					channels={this.props.channels} />
				{this.currentDialog && !this.state.isLoading ?
					<Chat
						department={this.props.departments.find((c) => c.id == this.currentDialog.departmentId)}
						messages={this.messages}
						page={this.currentDialog.getPage()}
						client={this.currentDialog?.client.name}
						employee={this.currentDialog?.employee}
						status={this.currentDialog.status}
						isAccepted={this.currentDialog.isAccepted()}
						isEmployeeOwner={this.currentDialog.isEmployeeOwner(this.props.employeeId)}
						renderEmployeeName={this.renderEmployeeName.bind(this)}
						onUpdate={this.onUpdate.bind(this)}
						onStatusChange={this.onStatusChange.bind(this)}
						onSend={this.onSend.bind(this)}
					/>
					: null}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		channels: state.account.channels,
		departments: state.account.departments,
		employeeId: state.account.id
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
