export const INVALID_ID = 101;
export const EMPLOYEE_IS_BLOCK = 102;
export const INVALID_CREDENTIALS = 103;
export const SERVER_ERROR = 104;
export const INVALID_PARAMS = 105;
export const REPEAT_PASSWORD_IS_INCORRECT = 116;
export const INVALID_EMAIL = 117;

export const ERROR_MESSAGES = {
	[INVALID_CREDENTIALS]: 'Неверная почта или пароль',
	[EMPLOYEE_IS_BLOCK]: 'Аккуант заблокирован',
	[REPEAT_PASSWORD_IS_INCORRECT]: 'Пароли не совпадают',
	[INVALID_EMAIL]: 'Невалидная почта'
}

export const MESSAGE_TYPE = {
	TEXT: 1,
	EVENT: 2,
	FILE: 3
}

export const MESSAGE_AUTHOR = {
	CLIENT: 1,
	EMPLOYEE: 2
}

export const TAB_LIST = {
	INBOX: 1,
	ALL: 2,
	ARCHIVE: 3
}

export const DIALOG_STATUSES = {
	UNACTIVE: 1,
	ACTIVE: 2,
	ACCEPTED: 3,
	CLOSED: 4,
	CLOSED_BY_CLIENT: 5
}

export const LANGUAGES = {
	RUSSIAN: {
		id: 1,
		value: 'Русский'
	},
	ENGLISH: {
		id: 2,
		value: 'Английский'
	}
}

export const SETTINGS_TAB = {
	MAIN: 1,
	NOTIFICATIONS: 2,
	DEPARTMENTS: 3,
	FORM: 3,
	EMPLOYEES: 4
}



export const ROLES_LIST = [
	{
		id: 1,
		name: 'Менеджер'
	},
	{
		id: 2,
		name: 'Оператор'
	}
];

export const ROLES = {
	MANAGER: ROLES_LIST[0].id,
	OPERATOR: ROLES_LIST[1].id
}

export const SETTINGS_ITEMS = [
	'isChatHideWhenOperatorsOffline',
	'selectChannel'
];