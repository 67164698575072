import React, { Component } from 'react';
import Container from './Container';

export default class Login extends Component {
	state = {
		email: '',
		password: ''
	};
	setEmail(event) {
		this.setState({
			email: event.currentTarget.value
		});
	}
	setPassword(event) {
		this.setState({
			password: event.currentTarget.value
		});
	}
	onSubmit() {
		this.props.onSubmit(this.state.email, this.state.password);
	}
	render() {
		return (
			<Container>
				<div class="login-page">
					<form id="login" class="login-form" action="" >
						<div class="login-form__title">Войти</div>
						<div class="form-unit">
							<p class="form_error">{this.props.error}</p>
						</div>
						<div class="form-unit">
							<input type="email" name="login_mail" id="login-mail" value={this.state.email} placeholder="Email" onChange={this.setEmail.bind(this)} />
						</div>
						<div class="form-unit">
							<input type="password" name="login_pass" id="login-pass" value={this.state.password} placeholder="Пароль" onChange={this.setPassword.bind(this)} />
						</div>
						<div class="form-unit form-unit--submit">
							<input disabled={!this.state.email || !this.state.password} class="btn btn--yellow" type="submit" value="Войти" onClick={this.onSubmit.bind(this)} />
							{/* <div class="request-pass">
                <a href="" class="request-pass__link">Забыли пароль?</a>
              </div> */}
						</div>
					</form>
				</div>
			</Container>
		);
	}
}
