import React, { Component } from 'react';

export default class LinkEmployeesToDepartment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: [...props.department.employees]
		}
	}

	toggleEmployeeCheckBox(id) {
		id = Number(id);
		let selected = this.state.selected;
		const index = selected.findIndex(e => e == id);
		if (index == -1) {
			selected.push(id);
		}
		else {
			if (selected.length == 0) {
				selected = [];
			}
			else {
				selected.splice(index, 1);
			}

		}
		this.setState({
			selected
		})
	}

	renderEmployee(employee) {
		return (
			<div onClick={() => this.toggleEmployeeCheckBox(employee.id)} class="checkbox-unit">
				<input checked={this.state.selected.includes(employee.id)} class="checkbox-unit__input" type="checkbox" name="operator_channel_2" />
				<label for="operator-channel-2" class="checkbox-unit__label">{employee.name}</label>
			</div>
		)

	}

	onSubmit() {
		this.props.onSubmit(this.state.selected);
	}

	render() {
		const { department, employees } = this.props;
		return (
			<div class="chat-main__main-content">
				<div class="settings-page">
					<div class="settings-page__main single-channel-add">
						<div class="settings-main">
							<form action="" id="add-operator" class="add-operator-form">
								<div class="channel-settings-row">
									<div class="channel-settings-row__title channel-settings-row__title--bold">Добавить оператора</div>
									<div class="channel-settings-row__items">
										<div class="options-list">
											<div class="option-item option-item--top">
												<div class="option-item__title">Отдел: {department.name}</div>
												<div class="option-item__element">
													{employees.map(this.renderEmployee.bind(this))}
												</div >
											</div>
											<div class="action-row">
												<button onClick={this.onSubmit.bind(this)} class="btn btn--green action-button" type="submit">Сохранить</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div></div></div></div>
		)
	}
}

