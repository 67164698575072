import AccountApi from './../api/account';

const ENDPOINT = 'auth/';

export const RESET_ACCOUNT_DATA = 'RESET_ACCOUNT_DATA';
export const LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const LOGIN_FAILED = 'ACCOUNT_LOGIN_FAILED';
export const SET_ERROR = 'SET_ACCOUNT_ERROR';

export function login(email, password) {
    return async (dispatch) => {
        const { result, data, error } = await AccountApi.login({
            email,
            password
        });
        if (result) {
            AccountApi.saveToken(data.token);
            delete data.token;
            return dispatch({
                type: LOGIN_SUCCESS,
                ...data
            });
        } else {
            return dispatch({
                type: LOGIN_FAILED,
                error
            });
        }
    }
}

export function auth() {
    return async (dispatch) => {
        if (!AccountApi.isLogged()) {
            return dispatch({
                type: LOGIN_FAILED
            });
        }
        const { result, error, data } = await AccountApi.getCurrent();
        console.log(data);
        if (!result) {
            return dispatch({
                type: LOGIN_FAILED,
                error
            });
        }
        return dispatch({
            type: LOGIN_SUCCESS,
            ...data
        });
    };
}

export function logout() {
    return async (dispatch) => {
        AccountApi.logout();
        return dispatch({
            type: RESET_ACCOUNT_DATA
        });
    };
}