import React, { Component } from 'react';
import { connect } from 'react-redux';
import Main from './Main';
import Loading from './../components/GlobalLoading';
import { getByEmployee as getDepartments } from './../actions/department';
import { getByEmployee as getChannels } from './../actions/channel';

class Launch extends Component {
	state = {
		isLoading: true
	};

	async componentWillMount() {
		await this.props.getChannels(this.props.accountId);
		await this.props.getDepartments(this.props.accountId);

		this.setState({
			isLoading: false
		});
	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}
		return <Main history={this.props.history} />;
	}
}

function mapStateToProps(state) {
	return {
		isLogged: state.account.isLogged,
		accountId: state.account.id
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getChannels: (id) => dispatch(getChannels(id)),
		getDepartments: (id) => dispatch(getDepartments(id)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Launch);
