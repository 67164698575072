import ChannelApi from '../api/channels';

export const SET_LIST = 'SET_CHANNEL_LIST';

export function getByEmployee(id) {
    return async (dispatch) => {
        const { result, data } = await ChannelApi.getListByEmployee(id);
        if (result) {
            return dispatch({
                type: SET_LIST,
                payload: data
            });
        }
    }
}

