import React, { Component } from 'react';

export default class ClientListSearch extends Component {

	setSearchText(ev) {
		this.props.onSearchSet(ev.target.value);
	}

	render() {
		return (
			<div class="clients-search">
				<div class="clients-search__form">
					<form action="" class="clients-search-form" id="clients-search">
						<input onChange={this.setSearchText.bind(this)} type="text" name="clients_search_value" class="clients-search-form__text" placeholder="Поиск" />
						<input type="submit" value="" class="clients-search-form__submit" />
					</form>
					<div class="clients-search__results">
						<span>Найдено: {this.props.count}</span>
					</div>
				</div>
			</div>
		)
	}
}

