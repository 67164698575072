// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SettingsContainer from '../components/Settings/Container';
import { ROLES } from './../infrastructure/constants';
import Main from '../components/Settings/Main';
import Loading from '../components/Loading';
import SettingsStore from '../settingsStore';


class SettingsMainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    async componentWillMount() {
        this.channel = this.props.channels.find((c) => c.id == this.props.match.params.id);
        this.settings = await SettingsStore.get(this.channel.id);
        this.setState({
            isLoading: false
        })
    }

    async onSettingsChange(data) {
        await SettingsStore.set(this.channel.id, {
            isChatHideWhenOperatorsOffline: data.isChatHideWhenOperatorsOfflineProp
        });
        this.props.chatGateway.updateSettings(this.channel.id);
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <SettingsContainer
                isManager={this.props.accountRole == ROLES.MANAGER}
                channel={this.channel}>
                <Main
                    onSettingsChange={this.onSettingsChange.bind(this)}
                    isChatHideWhenOperatorsOfflineProp={this.settings.isChatHideWhenOperatorsOffline}
                    channel={this.channel} />
            </SettingsContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        channels: state.channel.list,
        accountRole: state.account.role
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsMainPage));
