import React, { Component } from 'react';

export default ({ children }) => {
	return (
		<div class="chat-main__clients">
			{children}
		</div>
	)
}

