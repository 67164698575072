import { combineReducers } from 'redux';
import account from './account';
import client from './client';
import channel from './channel';
import department from './department';
import employee from './employee';
import message from './message';

export default combineReducers({
  client,
  account,
  channel,
  department,
  employee,
  message
});
