import Api from "./../api";
import { Message } from "./Message";

export class MessageStore {
	store = {};
	api = null;
	LIMIT = 50;

	constructor(api) {
		this.api = api;
	}

	add(message) {
		const key = this.buildKey(message.clientId, message.channelId);
		if (this.store[key]) {
			this.store[key].push(message);
		}
	}

	buildKey(clientId, channelId) {
		return `${clientId}:${channelId}`;
	}

	async load(clientId, channelId) {
		const response = await this.api.getList({
			clientId,
			channelId,
			limit: this.LIMIT
		});
		return response.data.map((data) => new Message(data));
	}

	async get(clientId, channelId, options = { forceUpload: false }) {
		const key = this.buildKey(clientId, channelId);
		if (!this.store[key] || options.forceUpload) {
			this.store[key] = await this.load(clientId, channelId);
		}

		return [...this.store[key]];
	}
}