import { Message } from "./Message";
import { MESSAGE_TYPE, MESSAGE_AUTHOR } from "../infrastructure/constants";

export class MessageCreator {
	createUID(message, date) {
		const res = message + date;
		return res.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0)
	}

	client(data) {
		return new Message(data);
	}

	employee(text, { token, channelId }) {
		const createdAt = new Date();
		return new Message({
			uid: this.createUID(text, createdAt),
			value: text,
			createdAt,
			token,
			channelId,
			type: MESSAGE_TYPE.TEXT,
			author: MESSAGE_AUTHOR.EMPLOYEE
		});
	}

	close(data) {
		return new Message({
			uid: '',
			value: '',
			createdAt: data.createdAt,
			token: '',
			channelId: null,
			type: MESSAGE_TYPE.EVENT,
			author: MESSAGE_AUTHOR.CLIENT
		});
	}
}

