import Routes from "./../infrastructure/routes.json";
import { MESSAGE_TYPE } from "./../infrastructure/constants";


export class ChatGateway {
	chatEntity = null;
	messageStore = null;
	messagesListener = null;
	dialogsListener = null;
	history = null;

	constructor(chatEntity, messageStore, history) {
		this.chatEntity = chatEntity;
		this.history = history;
		this.messageStore = messageStore;
		this.currentDialogToken = null;

		this.chatEntity.setMessageListener(this.onClientMessage.bind(this));
		this.chatEntity.setDialogsUpdateListener(this.onDialogsUpdate.bind(this));
	}

	onClientMessage({ token, message }) {
		console.log(message);
		this.messageStore.add(message);
		if (this.messagesListener != null && this.currentDialogToken == token) {
			this.messagesListener(message);
		}
		if (message.type == MESSAGE_TYPE.TEXT && this.currentDialogToken != token) {
			this.createNotification(token, message);
		}
	}

	sendTextMessage(dialog, text) {
		const message = this.chatEntity.sendMessage(text, {
			token: dialog.token,
			channelId: dialog.channelId
		});
		this.messageStore.add(message);

		return message;
	}

	onDialogsUpdate(dialogs) {
		if (this.dialogsListener != null) {
			this.dialogsListener(dialogs);
		}
	}

	setDialogsListener(handler) {
		this.dialogsListener = handler;
	}

	setMessagesListener(handler) {
		this.messagesListener = handler;
	}

	setCurrentDialogToken(token) {
		if (this.currentDialogToken) {
			this.chatEntity.resetUnread(this.currentDialogToken);
		}
		this.chatEntity.resetUnread(token);
		this.currentDialogToken = token;
	}

	acceptDialog(dialog) {
		this.chatEntity.acceptDialog({
			token: dialog.token,
			channelId: dialog.channelId
		});
	}

	closeDialog(dialog) {
		this.chatEntity.closeDialog({
			token: dialog.token,
			channelId: dialog.channelId
		});
	}

	setToActiveDialog(dialog) {
		this.chatEntity.setToActiveDialog({
			token: dialog.token,
			channelId: dialog.channelId
		});
	}

	getDialogs() {
		return this.chatEntity.dialogRepository;
	}

	getMessages(dialog) {
		return this.messageStore.get(dialog.client.id, dialog.channelId);
	}

	updateSettings(channelId) {
		this.chatEntity.updateSettings(channelId);
	}

	updateDepartments(channelId) {
		this.chatEntity.updateDepartments(channelId);
	}

	createNotification(token, message) {
		let myNotification = new Notification('Новое сообщение', {
			body: message.type == 3 ? 'Файл' : message.value
		});
		myNotification.onclick = () => {
			this.history(`${Routes.HOME}?token=${token}`);
		};
	}
}