import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Routes from './../../infrastructure/routes.json';

export default class SettingsContainer extends Component {
	render() {
		const { children, channel, isManager } = this.props;

		return (
			<div class="chat-main__main-content">
				<div class="settings-page">
					<div class="settings-page__side">
						<div class="settings-channels">
							<div class="settings-channels__top">
								<div class="title-box">
									<div class="title-box__pre-title">Настройки канала</div>
									<div class="title-box__title">{channel.name}</div>
								</div>
								<div class="menu-block">
									<ul class="menu-block__list">
										<li><Link to={`${Routes.SETTINGS_MAIN}${channel.id}`}>Основные</Link></li>
										{isManager ? <li><Link to={`${Routes.SETTINGS_EMPLOYEES}${channel.id}`}>Операторы</Link></li> : null}
										{/* <li><Link to={Routes.SETTINGS_NOTIFICATIONS}>Уведомления</Link></li> */}
										{isManager  ? <li><Link to={`${Routes.SETTINGS_DEPARTMENTS}${channel.id}`}>Отделы</Link></li> : null}
										{/* <li><Link to={Routes.SETTINGS_FORM}>Внешний вид</Link></li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
					{children}
				</div>
			</div>
		)
	}
}
