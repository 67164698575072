// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getByChannel as getDepartments } from '../actions/department';
import { getByChannel as getEmployees } from '../actions/employee';
import { withRouter } from 'react-router-dom';
import DepartmentAPI from './../api/departments';
import EmployeeAPI from './../api/employees';
import Routes from '../infrastructure/routes.json';
import SettingsContainer from '../components/Settings/Container';
import Departments from '../components/Settings/Departments';
import Loading from '../components/Loading';
import { ROLES } from '../infrastructure/constants';
import SettingsStore from '../settingsStore';

class SettingsDepartmentsPage extends Component {

    state = {
        isLoading: true
    }

    async componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.channel = this.props.channels.find((c) => c.id == this.props.match.params.id);
        this.settings = await SettingsStore.get(this.channel.id);
        await this.getDepartments();
        
        this.setState({
            isLoading: false
        });
    }

    async onSettingsChange(data) {
        await SettingsStore.set(this.channel.id, {
            selectChannel: data.selectChannelProp
        });
        this.props.chatGateway.updateSettings(this.channel.id);
    }

    async getEmployees(channelId) {
        const response = await EmployeeAPI.getMany({
            channelId
        });
        if (!response.result) {
            return [];
        }
        return response.data;
    }

    async getDepartments() {
        await this.props.getDepartments(this.channel.id);
    }

    async remove(departmentId) {
        this.setState({
            isLoading: true
        });
        await DepartmentAPI.remove(departmentId);
        await this.getDepartments();
        this.setState({
            isLoading: false
        });
    }

    onAddEmployee(departmentId) {
        this.props.history.push(`${Routes.LINK_EMPLOYEES_TO_DEPARTMENT}${this.channel.id}/${departmentId}`);
    }

    onAddDepartmentSubmit() {
        this.props.history.push(`${Routes.ADD_DEPARTMENT}${this.channel.id}`)
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <SettingsContainer
                isManager={ROLES.MANAGER == this.props.accountRole}
                channel={this.channel}>
                <Departments
                    addDepartmentSubmit={this.onAddDepartmentSubmit.bind(this)}
                    selectChannelProp={this.settings.selectChannel}
                    onSettingsChange={this.onSettingsChange.bind(this)}
                    addEmployee={this.onAddEmployee.bind(this)}
                    remove={this.remove.bind(this)}
                    list={this.props.departments} />
            </SettingsContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        departments: state.department.list,
        channels: state.channel.list,
        employees: state.employee.list,
        accountId: state.account.id,
        accountRole: state.account.role
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDepartments: (channelId) => dispatch(getDepartments(channelId)),
        getEmployees: (channelId) => dispatch(getEmployees(channelId))
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsDepartmentsPage));
