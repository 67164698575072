import React from 'react';

export default ({ children }) => {
	return (
		<div class="chat-main__messages">
			<div class="chat-list">
				{children}
			</div>
		</div>
	)
}