import DepartmentApi from '../api/departments';

export const SET_LIST = 'SET_DEPARTMENTS_LIST';


export function getByEmployee(id) {
	return async (dispatch) => {
		const { result, data } = await DepartmentApi.getListByEmployee(id);
		if (result) {
			return dispatch({
				type: SET_LIST,
				payload: data
			});
		}
	}
}



export function getByChannel(id) {
	return async (dispatch) => {
		const { result, data } = await DepartmentApi.getListByChannel(id);
		if (result) {
			return dispatch({
				type: SET_LIST,
				payload: data
			});
		}
	}
}


