import React, { Fragment, Component } from 'react';
import { TAB_LIST } from './../infrastructure/constants';
import ClientListContainer from './ClientListContainer';
import ClientListSearch from './ClientListSearch';


class OnlineList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ''
		}
	}

	onDialogPress(dialog) {
		this.props.onDialogPress(dialog);
	}

	onSearchSet(search) {
		this.setState({
			search
		})
	}

	search(dialogs, search) {
		return dialogs.filter((d) => d.client.name.includes(search) || d.client.email.includes(search));
	}

	build(allDialogs) {
		console.log(this.props.departments, allDialogs);
		let channels = [];
		this.props.channels.forEach((channel) => {
			console.log(channel);
			let dialogs = allDialogs.filter((d) => d.channelId == channel.id);
			console.log(dialogs);
			if (dialogs.length) {
				let departments = [];
				let isDialogsInChannelsExist = false;
				const departmentsWithOutDialogs = dialogs.filter((d) => !d.departmentId);
				if (departmentsWithOutDialogs.length) {
					isDialogsInChannelsExist = true;
					departments.push({
						name: 'Без отдела',
						dialogs: dialogs.filter((d) => !d.departmentId)
					});
				}
				this.props.departments.forEach((depart) => {
					const departDialogs = dialogs.filter((d) => d.departmentId == depart.id);
					if (departDialogs.length) {
						isDialogsInChannelsExist = true;
						departments.push({
							name: depart.name,
							dialogs: departDialogs
						});
					}
				});
				if (isDialogsInChannelsExist) {
					channels.push({
						name: channel.name,
						departments
					});
				}

			}
		});
		return channels;
	}

	renderDialogs(dialogs) {
		if (!dialogs.length) {
			return null;
		}
		return dialogs.map((dialog) => (
			<div onClick={() => this.onDialogPress(dialog)} class="client-message">
				<div class="client-message__avatar client-message__avatar--green"></div>
				<div class="client-message__info">
					<div class="client-message__title">
						<div class="client-message__name">{dialog?.client.name}</div>
						{dialog.token != this.props.currentToken && dialog.unread != 0 ?
							<div class="client-message__count"><span class="client-message__count-value">{dialog.unread}</span></div> :
							null}
					</div>
					{dialog.last ?
						<div class="client-message__content">
							<div class="client-message__text">{dialog.getLastMessage().author == 1 ? dialog.client.name : this.props.renderEmployeeName(dialog.emaployee)}</div>
							<div class="client-message__date">{dialog.getLastMessage().getDate()}</div>
						</div> : null}
				</div>
			</div>
		));
	}

	renderDepartments(departments) {
		if (!departments.length) {
			return null;
		}
		return departments.map((department) =>
			<Fragment>
				<span style={{ fontSize: 14, marginLeft: 18 }}>{department.name}</span>
				{this.renderDialogs(department.dialogs)}
			</Fragment>
		);
	}

	renderChannels(channels) {
		return channels.map((channel) =>
			<Fragment>
				<span style={{ marginLeft: 15 }}>{channel.name}</span><br /> <br />
				{this.renderDepartments(channel.departments)}
			</Fragment>
		);
	}

	render() {
		const { currentTab, onSetTab } = this.props;

		const filteredDialogs = this.search(this.props.dialogs, this.state.search);

		const result = this.build(filteredDialogs);

		return (
			<ClientListContainer >
				<ClientListSearch
					onSearchSet={this.onSearchSet.bind(this)}
					count={filteredDialogs.length} />

				<div class="clients-search__tabs">
					<nav class="tabs-list">
						<ul class="tabs-list-menu">
							<li class={`tabs-list-menu__item ${currentTab == TAB_LIST.INBOX ? 'tabs-list-menu__item--active' : ''}`}>
								<a onClick={() => onSetTab(TAB_LIST.INBOX)} class="tabs-list-menu__link">Входящие</a>
							</li>
							<li class={`tabs-list-menu__item ${currentTab == TAB_LIST.ALL ? 'tabs-list-menu__item--active' : ''}`}>
								<a onClick={() => onSetTab(TAB_LIST.ALL)} class="tabs-list-menu__link">Все</a>
							</li>
						</ul>
					</nav>
				</div>
				<div class="clients-search__list">
					{this.renderChannels(result)}
				</div>
			</ClientListContainer>
		);
	}
}

export default OnlineList;
