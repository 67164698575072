// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Routes from './../infrastructure/routes.json';
import DepartmentAPI from './../api/departments';
import AddDepartment from '../components/AddDepartment';
import Loading from '../components/Loading';


class AddDepartmentPage extends Component {

    state = {
        isLoading: true,
        error: ''
    };

    componentWillMount(){
        this.channelId = this.props.match.params.channelId;
        this.setState({
            isLoading: false
        });
    }

    async onSubmit({ name }) {
        this.setState({
            isLoading: true
        });
        const { result, error } = await DepartmentAPI.add({
            name,
            channelId: this.channelId
        });
        this.props.chatGateway.updateDepartments(this.channelId);
        if (!result) {
            this.setState({
                error,
                isLoading: false
            })
        }
        else {
            this.props.history.push(`${Routes.SETTINGS_DEPARTMENTS}${this.channelId}`);
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <AddDepartment
                error={this.state.error}
                onSubmit={this.onSubmit.bind(this)} />
        );
    }
}

function mapStateToProps(state) {
    return {
        channels: state.channel.list
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDepartmentPage));
