import React, { Component } from 'react';
import Picker from 'emoji-picker-react';

export default class Input extends Component {
	state = {
		text: '',
		isEmojiVisible: false
	};

	onSubmit = e => {
		if (this.state.text.replace(/\s/g, '').length) {
			this.props.onSend(this.state.text);
			this.setState({ text: '' });
		}
	};

	onChange = e => {
		this.setState({ text: e.target.value });
		if (this.props.onChange) {
			this.props.onChange();
		}
	};

	onEmojiClick(event, emojiObject) {
		this.setState({
			isEmojiVisible: false,
			text: `${this.state.text}${emojiObject.emoji}`
		})
	}

	toggleEmojiVisible() {
		this.setState({
			isEmojiVisible: !this.state.isEmojiVisible
		});
	}

	handleKeyDown(e) {
		if (e.key == 'Enter') {
			this.onSubmit();
		}
	}

	onKeyUp = event => {
		if (event.keyCode == 13) {
			if (!event.shiftKey) {
				event.preventDefault();
				this.onSubmit();
			}
		}
	};
	onFileUpload(e) {
		this.props.onFileUpload(e.target.files[0]);
	}
	render() {
		return (
			<div class="chat-list__input">
				<div class="chat-input-box">
					<div class="chat-input-box__types">
						<button class="message-type-switch message-type-switch--active">Чат</button>
						<button class="message-type-switch">Email</button>
						<button class="message-type-switch">SMS</button>
					</div>
					<div class="chat-input-box__field">
						<textarea
							onChange={this.onChange}
							onKeyDown={this.handleKeyDown.bind(this)}
							value={this.state.text}
							class="chat-message-input"
							placeholder="Введите сообщение">
						</textarea>
						<div class="chat-message-actions">
							{this.state.isEmojiVisible ?
								<div class="emoji-container">
									<Picker onEmojiClick={this.onEmojiClick.bind(this)} />
								</div> : null}
							<button onClick={this.toggleEmojiVisible.bind(this)} class="chat-message-actions__toggle chat-add-smiles"></button>
							<button onClick={this.onSubmit.bind(this)} class="chat-message-actions__toggle chat-send-message"></button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

