// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmployeeApi from './../api/employees';
import { getByChannel as getEmployees } from './../actions/employee';
import EmployeeItem from '../components/EmployeeItem';
import Loading from '../components/Loading';
import { validateEmail } from '../infrastructure/utils';
import Routes from './../infrastructure/routes';
import { INVALID_EMAIL, ERROR_MESSAGES } from '../infrastructure/constants';

class EditEmployeePage extends Component {

	state = {
		isLoading: true
	};

	componentDidMount() {
		const { match: { params } } = this.props;
		const employeeId = Number(params.employeeId);
		this.channelId = Number(params.channelId);
		this.employee = this.props.employees.find((op) => op.id == employeeId);
		this.setState({
			isLoading: false
		})
	}

	async onSubmit({ name, email, role }) {
		if (validateEmail(email)) {
			await EmployeeApi.save(this.employee.id, {
				name, email, role
			});
			this.props.history.push(`${Routes.SETTINGS_EMPLOYEES}${this.channelId}`);
			await this.getEmployees();
		}
		else {
			this.setState({
				error: ERROR_MESSAGES[INVALID_EMAIL]
			})
		}
	}

	async getEmployees() {
		await this.props.getEmployees(this.channelId);
	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}
		return (
			<EmployeeItem
				isEdit={true}
				error={this.state.error}
				employee={this.employee}
				onSubmit={this.onSubmit.bind(this)}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		error: state.employee.error,
		employees: state.employee.list,
		channels: state.channel.list
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getEmployees: (channelId) => dispatch(getEmployees(channelId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployeePage);
