import Api from '.';

class Employee extends Api {
	ENDPOINT = 'employees/'

	constructor() {
		super();
	}

	add(data) {
		return this.post(this.ENDPOINT, data);
	}

	remove(id) {
		return this.delete(`${this.ENDPOINT}${id}`);
	}

	save(id, data) {
		return this.put(`${this.ENDPOINT}${id}`, data);
	}

	getListByChannel(channelId) {
		return this.get(`employees?channelId=${channelId}`);
	}

	getMany({
		channelId,
		departmentId
	}) {
		return this.get(`employees`, { channelId, departmentId });
	}
}

const employee = new Employee();

export default employee;