// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getByEmployee as getChannels } from './../actions/channel';
import Loading from '../components/Loading';
import SettingsChannels from '../components/Settings/Channels';


class SettingsChannelsPage extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    async componentDidMount() {
        console.log(this.props.accountId);
        if (this.props.channels.length == 0) {
            await this.props.getChannels(this.props.accountId);
        }
        this.setState({
            isLoading: false
        })
    }


    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <SettingsChannels channels={this.props.channels} />
        );
    }
}

function mapStateToProps(state) {
    return {
        channels: state.channel.list,
        accountId: state.account.id
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getChannels: (accountId) => dispatch(getChannels(accountId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsChannelsPage);
