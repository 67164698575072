// @flow
import React from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import Menu from './../components/Menu.js';
import Routes from './Routes';
import ChatHandler from './../chat/Handler';
import history from './../history';
import routes from './../infrastructure/routes';
import Container from '../components/Container';

const Main = ({ id, name }) => {
    const withChat = ChatHandler(history, {
        id, name
    });

    history.push(routes.HOME);
    return (
        <Container>
            <Router history={history}>
                <Menu />
                <Routes withChat={withChat} />
            </Router>
        </Container>
    );
}

function mapStateToProps(state) {
    return {
        id: state.account.id,
        name: state.account.name
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
