import DialogRepository from "./DialogRepository";
import MessagesCreator from './../messages/creator';
import Dialog from "./Dialog";

export class ChatEntity {
	employee = null;
	messageCreator = null;
	chatAp = null;
	chatGateway = null;
	dialogRepository = null;
	clientMessageListener = null;
	dialogsUpdateListener = null;

	constructor(chatApi, messageCreator, employee) {
		this.chatApi = chatApi;
		this.chatApi.connect();

		this.employee = employee;
		this.messageCreator = messageCreator;
		this.dialogRepository = new DialogRepository();
		this.subscribe();
	}

	subscribe() {
		this.chatApi.onClientClose(this.onClientClose.bind(this));
		this.chatApi.onClientMessage(this.onClientMessage.bind(this));
		this.chatApi.onDialogUpdate(this.onDialogUpdate.bind(this));
		this.chatApi.onOnlineDialogs(this.onOnlineDialogs.bind(this));
	}

	onOnlineDialogs(dialogs) {
		this.dialogRepository.clear();
		dialogs.forEach((d) => {
			this.dialogRepository.add(new Dialog(d));
		});
		this.dialogsUpdateListener(this.dialogRepository);
	}

	onDialogUpdate(updatedDialog) {
		const dialog = this.dialogRepository.getByToken(updatedDialog.token);
		if (!dialog) {
			this.dialogRepository.add(new Dialog(updatedDialog));
		}
		else {
			dialog.update(updatedDialog);
			this.dialogRepository.update(dialog);
		}
		this.dialogsUpdateListener(this.dialogRepository);
	}

	onClientMessage(data) {
		const { token, departmentId } = data;
		const message = this.messageCreator.client(data.message);

		const dialog = this.dialogRepository.get({
			token,
			channelId: message.channelId
		});

		if (!dialog.isAccepted()) {
			dialog.up();
		}
		dialog.setDepartment(departmentId);
		dialog.addUnread();
		dialog.setMessageLast(message);

		this.dialogsUpdateListener(this.dialogRepository);
		if (this.clientMessageListener != undefined) {
			this.clientMessageListener({
				token: dialog.token,
				message
			});
		}
	}

	acceptDialog({ token, channelId }) {
		this.chatApi.acceptDialog({
			employee: this.employee,
			token,
			channelId
		});
	}

	closeDialog({ token, channelId }) {
		this.chatApi.closeDialog({
			employee: this.employee,
			token,
			channelId
		});
	}

	setToActiveDialog({ token, channelId }) {
		const dialog = this.dialogRepository.get({ token, channelId });
		if (dialog) {
			dialog.active();
			this.dialogsUpdateListener(this.dialogRepository);

			this.chatApi.setToActiveDialog({
				employee: this.employee,
				token,
				channelId
			});
		}

	}

	resetUnread(token) {
		const dialog = this.dialogRepository.getByToken(token);
		if (dialog) {
			dialog.resetUnreadMessages();
		}
	}

	onClientClose({ token, channelId, date }) {
		const dialog = this.dialogRepository.get({
			token, channelId
		});
		if (dialog) {

			if (dialog.employee && dialog.employee.id == this.employee.id) {
				dialog.closeByClient();
				this.dialogRepository.update(dialog);
			}
			else {
				this.dialogRepository.remove(dialog);
			}

			this.dialogsUpdateListener(this.dialogRepository);
			const message = this.messagesCreator.close({
				createdAt: date
			});

			this.clientMessageListener({
				token: dialog.token,
				clientId: dialog.client.id,
				message
			});
		}
	}

	sendMessage(text, { channelId, token }) {
		const message = this.messageCreator.employee(text, { channelId, token });
		const dialog = this.dialogRepository.get({ channelId, token });
		dialog.setMessageLast(message);
		this.dialogRepository.update(dialog);
		this.dialogsUpdateListener(this.dialogRepository);
		this.chatApi.sendMessage({
			value: message.value,
			type: message.type,
			uid: message.uid,
			token: message.token,
			channelId: message.channelId
		});

		return message;
	}

	updateSettings(channelId) {
		this.chatApi.updateSettings(channelId);
	}

	updateDepartments(channelId) {
		this.chatApi.updateDepartments(channelId);
	}

	setMessageListener(listener) {
		this.clientMessageListener = listener;
	}

	setDialogsUpdateListener(listener) {
		this.dialogsUpdateListener = listener;
	}

	emitUpdateDialogs() {
		this.chatGateway.onUpdateDialogs(this.dialogRepository);
	}
}
