import React, { Component, Fragment } from 'react';
import Download from '@axetroy/react-download';

const EVENTS = {
	1: () => 'Клиент закрыл сайт',
	2: (name = '') => `Оператор ${name} завершил диалог`,
	3: (name = '') => `Оператор ${name} принял диалог`
};

class MessageList extends Component {
	constructor(props) {
		super(props);
		this.lastMessageDate = null;
	}

	componentDidMount() {
		this.lastScroll = Date.now();
		this.scrollToBottom();
	}
	componentDidUpdate() {

		this.scrollToBottom();

	}
	scrollToBottom = () => {
		this.messagesEnd.scrollIntoView({ behavior: "auto" });
	};

	render() {
		const { clientName, messages, source } = this.props;
		return (
			<Fragment>
				<div class="chat-list__head">
					<div class="chat-list__current-user">
						{clientName}
					</div>
					<div class="chat-list__current-info">
						<span class="chat-list__current-info-text">{source} </span>
						{/* <button class="chat-list__current-info-toggle chat-list-actions">
							Доступные события <i class="fas fa-angle-down"></i>
						</button> */}
					</div>
					{/* <div class="chat-messages-history-date">26 марта 2020</div> */}
				</div>
				<div id="chat-messages-block" class="chat-list__main">
					<div class="chat-messages-stream">
						{messages.map(this.renderMessage.bind(this))}
					</div>
					<div style={{ float: "left", clear: "both" }}
						ref={(el) => { this.messagesEnd = el; }}>
					</div>
				</div>
			</Fragment>

		)
	}
	createFileContent({ value, fileType }) {
		if (!fileType) {
			return;
		}
		const type = fileType.split('/')[0];
		if (type == 'image') {
			return (
				<img  src={value}></img>
			)
		}
		return (
			<Download file={value} content="# hello world">
				<button type="button">File</button>
			</Download>
		);
	}



	renderMessage(message) {

		if(!message) return;
		const { type, value, author, employee } = message;
		const { clientName, renderEmployeeName } = this.props;

		let renderResult = [];

		if (!this.lastMessageDate || this.lastMessageDate != message.getDate()) {
			renderResult.push((
				<div class="chat-messages-stream__current-date">
					<span>{message.getDate()}</span>
				</div>));
		}
		this.lastMessageDate = message.getDate();

		if (type == 2 && message.event) {
			renderResult.push((
				<div class="chat-messages-stream__notification">{EVENTS[message.event](message.employee ? message.employee.name : '')}</div>
			))
		}

		else if (author == 1) {
			renderResult.push((<div class="message-unit message-unit--client">
				<div class="message-unit__user-avatar">
					<div class="client-avatar"></div>
				</div>
				<div class="message-unit__user-content">
					<div class="message-unit__user-inbox">
						<div class="chat-message-line">
							<div class="chat-message-line__inner">
								{value}
							</div>
						</div>
					</div>
					<div class="message-unit__user-meta">
						<span class="chat-user-meta-name">{clientName}</span>
						<span class="chat-user-meta-date">{message.getDate()} {message.getTime()}</span>
					</div>
				</div>
			</div>));
		}

		else if (author == 2) {
			renderResult.push((
				<div class="message-unit message-unit--operator">
					<div class="message-unit__user-avatar">
						<div class="client-avatar client-avatar--operator"></div>
					</div>
					<div class="message-unit__user-content">
						<div class="message-unit__user-inbox">
							<div class="chat-message-line">
								<div class="chat-message-line__inner">{value}</div>
							</div>
						</div>
						<div class="message-unit__user-meta">
							<span class="chat-user-meta-name">{renderEmployeeName(employee)}</span>
							<span class="chat-user-meta-date">{message.getDate()} {message.getTime()}</span>
						</div>
					</div>
				</div>
			));
		}

		return renderResult;
	}
}

export default MessageList;
