import React, { Component } from 'react';
import { connect } from 'react-redux';
import Log from './../components/Log';
import Loading from './../components/Loading';


class LogPage extends Component {
	state = {
		isLoading: true
	};

	async componentDidMount() {
		this.props.getList(this.props.channel.id).then(() => {
			this.setState({
				isLoading: false
			});
		});

	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}
		return <Log list={this.props.clients} />
	}
}

function mapStateToProps(state) {
	return {
		clients: state.client.list,
		channel: state.channel.current

	};
}

function mapDispatchToProps(dispatch) {
	return {
		// getList: (channelId) => dispatch(getList(channelId))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LogPage);
