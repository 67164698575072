import React from 'react';
import { DIALOG_STATUSES } from '../../infrastructure/constants';

export default ({ isAccepted, onUpdate, onStatusChange, isCurrentEmployeeOwner, status }) => {
	if (!isCurrentEmployeeOwner && isAccepted) {
		return (
			<div class="manage-buttons-container">
				<button
					class="actions-group__item actions-group__item--btn btn btn--green"
					onClick={onUpdate}>
					Обновить
				</button>
				<br />

			</div>
		)
	}

	else if (status == DIALOG_STATUSES.UNACTIVE || status == DIALOG_STATUSES.CLOSED) {
		return (
			<div class="manage-buttons-container">
				<button
					class="actions-group__item actions-group__item--btn btn btn--green"
					onClick={() => onStatusChange(DIALOG_STATUSES.ACCEPTED)}>
					Вступить в диалог
				</button>
				<br />

			</div>
		)
	}

	return (
		<div class="manage-buttons-container">
			<button
				class="actions-group__item actions-group__item--btn btn btn--green"
				onClick={() => onStatusChange(DIALOG_STATUSES.ACCEPTED)}>
				Принять
			</button>
			<br />
			<button
				class="actions-group__item actions-group__item--btn btn btn--yellow"
				onClick={() => onStatusChange(DIALOG_STATUSES.CLOSED)}>
				Завершить
			</button>
		</div>
	)
}