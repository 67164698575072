// @flow
import { LOGIN_SUCCESS, LOGIN_FAILED, RESET_ACCOUNT_DATA } from '../actions/account';
import { ERROR_MESSAGES } from './../infrastructure/constants';

type TState = {
	role: number,
	name: string,
	email: string,
	error: number,
	id: number,
	isLogged: boolean,
	departments: array<any>,
	channels: array<any>
};

const defaultState = {
	isLogged: false,
	id: undefined,
	role: undefined,
	name: undefined,
	email: undefined,
	departments: [],
	channels: [],
	error: ''
};

export default function account(state: TState = defaultState, action) {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				isLogged: true,
				error: '',
				id: action.id,
				role: action.role,
				name: action.name,
				email: action.email,
				departments: action.departments,
				channels: action.channels
			};
		case LOGIN_FAILED: {
			let error = '';
			if (action.error) {
				error = ERROR_MESSAGES[action.error]
			}
			return {
				isLogged: false,
				error
			};
		}
		case RESET_ACCOUNT_DATA: {
			return defaultState;
		}
		default:
			return state;
	}
}
