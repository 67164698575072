import { getData, setData, deleteKey } from './../storage';

const USER_DATA_STORE_KEY = 'user-data-store-key1';
const API_TOKEN_STORE = 'token-store1';

export function getAccountData() {
	return getData(USER_DATA_STORE_KEY);
}

export function saveAccountData(data) {
	return setData(USER_DATA_STORE_KEY, data);
}

export function clearAuthData() {
	return deleteKey(USER_DATA_STORE_KEY);
}

export function removeToken() {
	return deleteKey(API_TOKEN_STORE);
}

export function saveToken(token) {
	return setData(API_TOKEN_STORE, token);
}

export function getToken() {
	const token = getData(API_TOKEN_STORE);
	if(typeof token == 'object'){
		return null;
	}
	return token;
}