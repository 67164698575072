import React from 'react';

export default ({ children }) => {
	return (
		<div id="page-wrapper" class="page">
			<div class="wrapper">
				<div class="chat">
					<main class="chat-main chat-main--col-2">
						{children}
					</main>
				</div>
			</div>
		</div>
	);
}
