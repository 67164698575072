import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from './../components/Loading';
import Launch from './Launch';
import LoginPage from './LoginPage';
import { auth } from './../actions/account';


class Boot extends Component {
    state = {
        isLoading: true
    };

    async componentWillMount() {
        await this.props.auth();
        this.setState({
            isLoading: false
        });

    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        } else if (!this.props.isLogged) {
            return <LoginPage />;
        }
        return <Launch history={this.props.history} />;
    }
}

function mapStateToProps(state) {
    return {
        isLogged: state.account.isLogged
    };
}

function mapDispatchToProps(dispatch) {
    return {
        auth: () => dispatch(auth())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Boot);
