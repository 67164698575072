import Api from '.';

class Account extends Api {
	ENDPOINT = 'auth/'

	constructor() {
		super();
	}

	login(data) {
		this.logout();
		return super.login(data);
	}

	isLogged(){
		return super.isLogged();
	}

	saveToken(data){
		return super.saveToken(data);
	}

	logout(){
		return super.logout();
	}

	getCurrent(){
		return this.get(this.ENDPOINT)
	}
}

const account = new Account();

export default account;