import React from 'react';
import { DIALOG_STATUSES } from './../../infrastructure/constants'
import AvatarImg from './../../assets/img/user-photo-placeholder.jpg';


export default ({ client, employee, statusChangeEnabled, status, onStatusChange }) => (
	<div class="chat-main__info">
		<div class="client-info">
			<div class="client-link">
				<div class="client-link__info">
					<div class="client-link__avatar client-avatar"></div>
					<div class="client-link__name">{client}</div>
				</div>
				<button class="client-link__copy copy-client-link">
					<i class="fas fa-link"></i>
				</button>
			</div>
			<select disabled={!statusChangeEnabled} onChange={(e) => onStatusChange(e.target.value)} name="client_status" id="client-status" class="client-status-switcher">
				<option selected={status == DIALOG_STATUSES.ACTIVE} value={DIALOG_STATUSES.ACTIVE}>Активный</option>
				<option selected={status == DIALOG_STATUSES.CLOSED} value={DIALOG_STATUSES.CLOSED}>Завершен</option>
				{status == DIALOG_STATUSES.UNACTIVE ?
					<option selected={status == DIALOG_STATUSES.UNACTIVE} value={DIALOG_STATUSES.UNACTIVE}>Неактивный</option>
					: null}
				{status == DIALOG_STATUSES.ACCEPTED ?
					<option selected={status == DIALOG_STATUSES.ACCEPTED} value={DIALOG_STATUSES.ACCEPTED}>Принят</option>
					: null}
				{status == DIALOG_STATUSES.CLOSED_BY_CLIENT ?
					<option selected={status == DIALOG_STATUSES.CLOSED_BY_CLIENT} value={DIALOG_STATUSES.CLOSED_BY_CLIENT}>Закрыт клиентом</option>
					: null}
			</select>
			<div class="operator-info">
				<div class="operator-info__avatar"><img src={AvatarImg} alt="Operator" /></div>
				<div class="operator-info__content">
					<div class="operator-info__status">Ответственный</div>
					<div class="operator-info__name">{employee}</div>
				</div>
				<div class="operator-info__toggle"><i class="fas fa-angle-down"></i></div>
			</div>
		</div>
	</div>
)

