// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';
import DepartmentAPI from './../api/departments';
import { getByEmployee as getDepartments } from '../actions/department';
import { getByChannel as getEmployees } from '../actions/employee';
import LinkEmployeesToDepartment from '../components/LinkEmployeesToDepartment';
import Loading from '../components/Loading';


class LinkEmployeesToDepartmentPage extends Component {
    state = {
        isLoading: true,
        error: ''
    };

    async componentDidMount() {
        const { match: { params } } = this.props;
        const departmentId = Number(params.departmentId);
        this.channelId = Number(params.channelId);
        if (!this.props.departments.length) {
            await this.props.getDepartments();
        }
        await this.props.getEmployees(this.channelId);

        this.department = this.props.departments.find((d) => d.id == departmentId)
        this.department.employees = this.department.employees.map((e) => e.id);

        this.setState({
            isLoading: false
        })
    }

    async onSubmit(selectedEmployees) {
        this.setState({
            isLoading: true
        });
        const add = lodash.difference(selectedEmployees, this.department.employees);
        const remove = lodash.difference(this.department.employees, selectedEmployees);
        const { result } = await DepartmentAPI.linkEmployees(this.department.id, { add, remove });
        if (result) {
            this.props.history.goBack();
        }
        else {
            this.setState({
                isLoading: false
            });
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <LinkEmployeesToDepartment
                employees={this.props.employees}
                department={this.department}
                error={this.state.error}
                onSubmit={this.onSubmit.bind(this)} />
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.channel.error,
        departments: state.department.list,
        employees: state.employee.list
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDepartments: (employeeId) => dispatch(getDepartments(employeeId)),
        getEmployees: (channelId) => dispatch(getEmployees(channelId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkEmployeesToDepartmentPage)

