import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../infrastructure/routes.json'
import FakeAvatar from '../../assets/img/user-photo-placeholder.jpg';


export default class SettingsDepartments extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectChannelProp: props.selectChannelProp
		}
	}

	onSelectChannelPropChange() {
		this.setState({
			selectChannelProp: !this.state.selectChannelProp
		}, () => {
			this.props.onSettingsChange({
				selectChannelProp: this.state.selectChannelProp
			});
		});
	}

	renderEmployee(employee) {
		return (
			<div class="user-unit">
				<img src={FakeAvatar} alt="User" class="user-unit__img" />
				<div class="user-unit__name">{employee.name}</div>
			</div>
		)
	}

	renderDepartment(department) {
		return (
			<div class="channel-row">
				<div class="channel-row__heading">
					<div class="channel-row__heading-title">{department.name}</div>
					<div class="channel-row__heading-buttons">
						<button onClick={() => this.props.addEmployee(department.id)} class="btn btn--green btn--green-w">+ Добавить оператора</button>
						<button onClick={() => this.props.remove(department.id)} class="btn btn--red btn--red-w">Удалить</button>
					</div>
				</div>
				<div class="channel-row__users">
					{department.employees.map(this.renderEmployee.bind(this))}
				</div>
			</div>
		)
	}

	render() {
		const { list, addDepartmentSubmit } = this.props;
		return (
			<div class="settings-page__main single-channel-departments">
				<div class="settings-header">
					<div class="settings-header__main-title">
						<div class="main-title">Отделы</div>
						<Link onClick={addDepartmentSubmit}>
							<button class="text-button text-button--green">+ Добавить</button>
						</Link>
					</div>
					<div class="options-list">
						<div class="option-item">
							<div class="option-item__title">Выбор отдела клиентом перед началом чата:</div>
							<div class="option-item__element">
								<div class="radio-switcher">
									<input checked={this.state.selectChannelProp} onChange={this.onSelectChannelPropChange.bind(this)} type="checkbox" name="send_statistic" id="send-statistic-checkbox" class="radio-switcher__input" />
									<label for="send-statistic-checkbox" class="radio-switcher__label"></label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="settings-main">
					<div class="channels-list">
						{list.map(this.renderDepartment.bind(this))}
					</div>
				</div>
			</div>
		)
	}
}

