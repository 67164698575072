import React from 'react';
import { ChatAPI } from './Api';
import AccountApi from '../api/account';
import MessageApi from '../api/messages';
import { ChatEntity } from './Entity';
import { ChatGateway } from './Gateway';
import { MessageStore } from './MessageStore';
import { MessageCreator } from './MessageCreator';

export default (history, employee) => {
	const chatApi = new ChatAPI(AccountApi.getToken(), employee.id);
	const messageCreator = new MessageCreator();
	const chatEntity = new ChatEntity(chatApi, messageCreator, employee);
	const messageStore = new MessageStore(MessageApi);
	const chatGateway = new ChatGateway(chatEntity, messageStore, history);

	return (component) => {
		return React.createElement(component, {
			chatGateway
		});
	}
}
