import Api from './../api';

const ENDPOINT = 'clients/';

export const SET_LIST = 'SET_CLIENT_LIST';

// export function getList() {
//     return async (dispatch) => {
//         const { result, data } = await Api.get(`${ENDPOINT}channel\1`);
//         if (result) {
//             return dispatch({
//                 type: SET_LIST,
//                 list: data
//             });
//         }
//     };
// }
