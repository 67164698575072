// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login, auth } from './../actions/account';
import Login from '../components/Login';
import Loading from '../components/GlobalLoading';

class LoginPage extends Component {
	state = {
		isLoading: false
	}

	async onSubmit(email, password) {
		this.setState({
			isLoading: true
		});
		await this.props.login(email, password);
		if (!this.props.error) {
			await this.props.auth();
		}
		this.setState({
			isLoading: false
		});
	}

	render() {
		if (this.state.isLoading) {
			return <Loading />;
		}
		return <Login error={this.props.error} onSubmit={this.onSubmit.bind(this)} />;
	}
}

function mapStateToProps(state) {
	return {
		error: state.account.error
	};
}

function mapDispatchToProps(dispatch) {
	return {
		login: (email, password) => dispatch(login(email, password)),
		auth: () => dispatch(auth())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);