import { DIALOG_STATUSES } from '../infrastructure/constants';

export default class DialogRepository {
	list = []

	add(dialog) {
		this.list.push(dialog);
	}

	remove(dialog) {
		const index = this.list.findIndex((d) => d.token == dialog.token);
		this.list.splice(index, index + 1);
	}

	update(dialog) {
		const index = this.list.findIndex((d) => d.token == dialog.token);
		this.list[index] = dialog;
	}

	getAll = () => this.list;
	getBy = (where) => this.list.filter(where);
	getNotActive = () => this.list.filter((d) => d.status != DIALOG_STATUSES.ACTIVE);
	getActive = () => this.list.filter((d) => d.status == DIALOG_STATUSES.ACTIVE);

	closeByClient = () => {
		this.status = DIALOG_STATUSES.CLOSED_BY_CLIENT
	}

	getByToken(token) {
		return this.list.find((d) => d.token == token);
	}

	get({ token, channelId }) {
		return this.list.find((d) => d.token == token && d.channelId == channelId);
	}

	getByConnectionId(connectionId) {
		return this.list.find((d) => d.connectionId == connectionId);
	}

	clear() {
		this.list = [];
	}
}