// @flow
import { SET_LIST } from '../actions/client';

type TState = {
	list: any,
	error: string
};

const defaultState = {
	list: [],
	error: undefined
};

export default function channel(state: TState = defaultState, action) {
	switch (action.type) {
		case SET_LIST:
			return {
				list: action.list
			};
		default:
			return state;
	}
}
