import React, { Component } from 'react';

export default class SettingsMain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChatHideWhenOperatorsOfflineProp: props.isChatHideWhenOperatorsOfflineProp
		}
	}

	isChatHideWhenOperatorsOfflinePropChange() {
		this.setState({
			isChatHideWhenOperatorsOfflineProp: !this.state.isChatHideWhenOperatorsOfflineProp
		}, () => {
			this.props.onSettingsChange({
				isChatHideWhenOperatorsOfflineProp: this.state.isChatHideWhenOperatorsOfflineProp
			})
		});

	}

	render() {
		const { channel } = this.props;
		return (
			<div class="settings-page__main single-channel-settings">
				<div class="settings-header">
					<div class="settings-header__main-title">
						<div class="single-channel-name inline-input">
							<div class="inline-input__title">Название канала</div>
							<input type="text" value={channel.name} class="inline-input__field" placeholder="kassa24" />
						</div>
						{/* <button class="text-button text-button--red">Удалить канал</button> */}
					</div>
				</div>
				<div class="settings-main">
					<div class="channel-settings-row">
						<div class="channel-settings-row__title">Установка</div>
						<div class="channel-settings-row__items">
							<p>Для того, чтобы принимать обращения клиентов с сайта, вам необходимо установить следующий код на каждую старницу вашего сайта перед закрывающим тегом {'</head>:'}</p>
							<div class="copy-code">
								<input type="text" class="copy-code__input" readonly value="empty" />
								<button class="copy-code__btn copy-widget-code">
									<i class="fas fa-link"></i>
								</button>
							</div>
						</div>
					</div>

					<div class="channel-settings-row">
						<div class="channel-settings-row__title">Опции</div>
						<div class="channel-settings-row__items">
							<div class="options-list">
								<div class="option-item">
									<div class="option-item__title">Отображение чата если операторы не в сети:</div>
									<div class="option-item__element">
										<div class="radio-switcher">
											<input checked={!!this.state.isChatHideWhenOperatorsOfflineProp} onChange={this.isChatHideWhenOperatorsOfflinePropChange.bind(this)} type="checkbox" name="show_chat" id="show-chat-checkbox" class="radio-switcher__input" />
											<label for="show-chat-checkbox" class="radio-switcher__label"></label>
										</div>
									</div>
								</div>

								{/* <div class="option-item">
									<div class="option-item__title">Наблюдение за клиентом</div>
									<div class="option-item__element">
										<div class="radio-switcher">
											<input type="checkbox" name="client_watching_checkbox" id="client-watching-checkbox" class="radio-switcher__input" />
											<label for="client-watching-checkbox" class="radio-switcher__label"></label>
										</div>
									</div>
								</div>

								<div class="option-item">
									<div class="option-item__title">Оценка оператора:</div>
									<div class="option-item__element">
										<div class="radio-switcher">
											<input type="checkbox" name="operator_rating" id="operator-rating-checkbox" class="radio-switcher__input" />
											<label for="operator-rating-checkbox" class="radio-switcher__label"></label>
										</div>
									</div>
								</div>

								<div class="option-item">
									<div class="option-item__title">Часовой пояс</div>
									<div class="option-item__element">
										<select name="time_zone" id="time-zone-select" class="choose-time-zone select-white">
											<option value="(GMT +03:00) Москва">(GMT +03:00) Москва</option>
											<option value="(GMT +03:00) Москва">(GMT +03:00) Москва</option>
										</select>
									</div>
								</div> */}
							</div>
						</div>
					</div>

				</div>
			</div>
		)
	}
}