import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Routes from './../infrastructure/routes.json';

export default class Log extends Component {
	formatClientName(client) {
		if (client.name) {
			return client.name;
		}
		return `Клиент ${client.id}`;
	}
	renderClient(client) {
		return (
			<tr class="clients-list-table__row">
				<td class="clients-list-table__client-name">
					<div class="client-message">
						<div class="client-message__avatar client-message__avatar--purple"></div>
						<div class="client-message__info">
							<div class="client-message__title">
								<div class="client-message__name">{this.formatClientName(client)}</div>
							</div>
						</div>
					</div>
				</td>
				{/* <td class="clients-list-table__client-time">32 мин</td>
				<td class="clients-list-table__client-page">Билеты на концерт Ария</td>
				<td class="clients-list-table__count-cell">1</td>
				<td class="clients-list-table__count-cell">0</td>
				<td class="clients-list-table__count-cell">1</td>
				<td class="clients-list-table__count-cell"></td>
				<td class="clients-list-table__count-cell"><i class="fas fa-mouse-pointer"></i></td> */}
				<td class="clients-list-table__action-cell"><Link to={`${Routes.CLIENT_LOG}${client.token}`} class="btn btn--green">Просмотр</Link></td>
			</tr>
		)
	}
	render() {
		const { list } = this.props;
		return (
			<Fragment>
				<div class="chat-main__main-content">
					<div class="inner-page-head">
						<h1 class="inner-page-head__title">kassa24.ru</h1>
					</div>
					<div class="inner-page-content">
						<table class="clients-list-table">
							<thead class="clients-list-table__head">
								<tr>
									<th class="clients-list-table__client-name">Посетители</th>
									{/* <th class="clients-list-table__client-time">На сайте</th>
									<th class="clients-list-table__client-page">Страница</th>
									<th class="clients-list-table__count-cell"><i class="fas fa-sign-in-alt"></i></th>
									<th class="clients-list-table__count-cell"><i class="far fa-comment"></i></th>
									<th class="clients-list-table__count-cell"><i class="far fa-eye"></i></th>
									<th class="clients-list-table__count-cell"><i class="far fa-user"></i></th>
									<th class="clients-list-table__count-cell"><i class="far fa-arrow-alt-circle-right"></i></th> */}
									<th class="clients-list-table__action-cell">В диалоге</th>
								</tr>
							</thead>
							<tbody class="clients-list-table__body">
								{list.map(this.renderClient.bind(this))}
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}

