import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './../infrastructure/routes';
import HomePage from './HomePage';
import AddEmployeePage from './AddEmployeePage';
import AddDepartmentPage from './AddDepartmentPage';
import SettingsMainPage from './SettingsMainPage';
import SettingsDepartmentsPage from './SettingsDepartmentsPage';
import LogPage from './LogPage';
import LinkEmployeesToDepartmentPage from './LinkEmployeesToDepartmentPage';
import SettingsEmployeesPage from './SettingsEmployeesPage';
import EditEmployeePage from './EditEmployeePage';
import SettingsChannelsPage from './SettingsChannelsPage';


export default ({ withChat }) => {
  return (
    <Switch>
      {/* <Route path={`${routes.CLIENT_LOG}:clientToken`} component={ClientLogPage} /> */}
      <Route path={`${routes.SETTINGS_MAIN}:id`}>
        {withChat(SettingsMainPage)}
      </Route>
      <Route path={`${routes.SETTINGS_DEPARTMENTS}:id`}>
        {withChat(SettingsDepartmentsPage)}
      </Route>
      <Route path={routes.LOG} component={LogPage} />
      <Route path={`${routes.SETTINGS_EMPLOYEES}:id`} component={SettingsEmployeesPage} />
      <Route path={`${routes.SETTINGS_CHANNELS}`} component={SettingsChannelsPage} />

      <Route path={`${routes.LINK_EMPLOYEES_TO_DEPARTMENT}:channelId/:departmentId/`} component={LinkEmployeesToDepartmentPage} />
      <Route path={`${routes.EDIT_EMPLOYEE}:channelId/:employeeId`} component={EditEmployeePage} />
      <Route path={`${routes.ADD_DEPARTMENT}:channelId`}>
        {withChat(AddDepartmentPage)}
      </Route>
      <Route path={`${routes.ADD_EMPLOYEE}:channelId`} component={AddEmployeePage} />
      <Route path={routes.HOME}>
        {withChat(HomePage)}
      </Route>
    </Switch>
  );
};
