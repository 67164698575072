import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from './../actions/account';
import { Link, withRouter } from 'react-router-dom';
import Routes from './../infrastructure/routes';

import FakeAvatar from './../assets/img/user-photo-placeholder.jpg';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.activeItemStyle = "side-navbar-item side-navbar-item--active";
		this.itemStyle = "side-navbar-item";
	}
	logout() {
		this.props.logout();
	}
	render() {
		const path = this.props.location.pathname;
		return (
			<div class="chat-main__sidebar">
				<div class="chat-sidebar">
					<div class="chat-sidebar__user-img">
						<img src={FakeAvatar} alt="User avatar" />
					</div>
					<nav class="chat-sidebar__menu">
						<ul class="side-navbar">
							<li class={path == Routes.HOME ? this.activeItemStyle : this.itemStyle}>
								<Link to={Routes.HOME} class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-comments"></i></span>
									<span class="side-navbar-item__title">Диалоги</span>
								</Link>
							</li>
							{/* <li class={path == Routes.LOG ? this.activeItemStyle : this.itemStyle}>
								<Link to={Routes.LOG} class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-book"></i></span>
									<span class="side-navbar-item__title">Журнал</span>
								</Link>
							</li> */}
							{/* <li >
								<a href="bot.html" class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-rocket"></i></span>
									<span class="side-navbar-item__title">Бот</span>
								</a>
							</li>
							<li class="side-navbar-item">
								<a href="orders.html" class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-address-card"></i></span>
									<span class="side-navbar-item__title">Заявки</span>
								</a>
							</li>
							<li class="side-navbar-item">
								<a href="contacts.html" class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-address-book"></i></span>
									<span class="side-navbar-item__title">Контакты</span>
								</a>
							</li>
							<li class="side-navbar-item">
								<a href="" class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-chart-area"></i></span>
									<span class="side-navbar-item__title">Графики</span>
								</a>
							</li> */}
						</ul>
						<ul class="side-navbar side-navbar--bottom">
							<li class={path.includes("settings") ? this.activeItemStyle : this.itemStyle}>
								<Link to={Routes.SETTINGS_CHANNELS} class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-cog"></i></span>
									<span class="side-navbar-item__title">Настройки</span>
								</Link>
							</li>
							<li class="side-navbar-item">
								<a onClick={this.logout.bind(this)} class="side-navbar-item__link">
									<span class="side-navbar-item__icon"><i class="fas fa-file"></i></span>
									<span class="side-navbar-item__title">Выход</span>
								</a>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
	};
}

function mapDispatchToProps(dispatch) {
	return {
		logout: () => dispatch(logout())
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
