import moment from 'moment/min/moment-with-locales';

export class Message {
	constructor({
		value = '',
		type,
		author,
		clientId = null,
		token = '',
		channelId = null,
		employee = null,
		createdAt,
		uid = '',
		event
	}) {
		this.uid = uid;
		this.value = value;
		this.type = type;
		this.token = token;
		this.event = event;
		this.author = author;
		this.clientId = clientId;
		this.channelId = channelId;
		this.employee = employee;
		this.createdAt = createdAt;
	}

	getDate() {
		return moment(new Date(this.createdAt)).locale('ru').format("DD MMMM YYYY");
	}

	getTime() {
		return moment(new Date(this.createdAt)).locale('ru').format("HH:mm");
	}
}
