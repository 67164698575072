import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmployeeApi from './../api/employees';
import { getByChannel as getEmployees } from '../actions/employee';
import Loading from '../components/Loading';
import EmployeeItem from '../components/EmployeeItem';
import Routes from './../infrastructure/routes';
import { validateEmail } from '../infrastructure/utils';
import { INVALID_EMAIL, ERROR_MESSAGES } from '../infrastructure/constants';


class AddEmployeePage extends Component {
    state = {
        isLoading: false,
        error: ''
    };

    componentWillMount() {
        this.channelId = this.props.match.params.channelId;
    }

    async onSubmit({ name, email, role, password }) {
        if (validateEmail(email)) {
            await EmployeeApi.add({
                name, email, role, password,
                channelId: this.channelId
            });
            this.props.history.push(`${Routes.SETTINGS_EMPLOYEES}${this.channelId}`);
            await this.getEmployees();
        }
        else {
            this.setState({
                error: ERROR_MESSAGES[INVALID_EMAIL]
            })
        }
    }

    async getEmployees() {
        await this.props.getEmployees(this.channelId);
    }

    render() {
        if (this.state.isLoading) {
            return <Loading />;
        }
        return (
            <EmployeeItem
                error={this.state.error}
                departments={this.props.departments}
                onSubmit={this.onSubmit.bind(this)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        error: state.employee.error,
        departments: state.employee.departments,
        channel: state.channel.current
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEmployees: (channelId) => dispatch(getEmployees(channelId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployeePage);
